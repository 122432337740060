<template>
    <div class="project">
        <div class="material_screen">
            <div class="mian">
                <div class="inner_nav clearfix">
                    <div class="fl inner_nav_l">当前位置：<span>每周一字</span> > 字体搜索</div>
                    <div class="fr inner_nav_r">共 <span>{{font_search_total}}</span> 个作品</div>
                </div>
                <router-link class="inner_banner" :to="{name:banner.url,query:{id:banner.links_id!=0?banner.links_id:''} }">
                    <img :src="banner.thumb" alt="" />
                </router-link>
                <div class="material_search">
                    <input type="text" v-model="keywords" placeholder="单字搜索更准确" @keyup.enter="listFun"/>
                    <div class="material_search_button" @click="listFun">
                        <img src="../assets/images/indexsearch.png" alt="" />
                    </div>
                </div>
                <div class="material_class_item">
                    <div class="class_list_title">类别：</div>
                    <div class="class_list clearfix">
                        <div class="fl" :class="cateIndex== index? 'active':''" @click="cateSelect(index)" v-for="(item,index) in cateList" :key="index">{{item.title}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mian">
            <div class="project_box">
                <div class="daily clearfix" v-if="listOne.length>0">
                    <!--  wow animate__animated animate__fadeInUp -->
                    <div class="fl daily_li" v-for="(item,index) in listOne" :key="index" @click="toDetail(item.id)">
                        <div class="daily_li_box">
                            <img v-lazy="item.thumb" :key="item.thumb" alt="">
                            <div class="daily_li_mask"></div>
                        </div>
                    </div>
                </div>
                <div class="ranking_ul clearfix" v-if="listTwo.length>0">
                    <!-- wow animate__animated animate__fadeInUp -->
                    <div class="fl ranking_daily_li " v-for="(item,index) in listTwo" :key="index" @click="toDetail(item.id)">
                        <div class="ranking_li_box">
                            <img v-lazy="item.thumb" alt="">
                        </div>
                        <div class="ranking_li_name">{{item.title}}</div>
                    </div>
                </div>
                <el-empty v-if="listOne.length==0 && listTwo.length==0" description="暂无数据"></el-empty>
                <page v-if="total>16" :page="page" :limit="limit" :total="total" ref="page"></page>
            </div>
        </div>
        <!-- 侧边导航 -->
        <sidebar ref="downtotal"></sidebar>
        <!-- 收藏 -->
        <div class="mask" v-if="isShow" @click="closeMsg">
            <collection isMask="1" :collectionId="collId" :type="type" @closeFun="closeFun"></collection>
        </div>
    </div>
</template>

<script>
// import { WOW } from 'wowjs'
import sidebar from '../components/sidebar.vue'
import collection from '../components/collection.vue'
import page from '../components/page.vue'
export default {
    name: "project",
    components:{
        sidebar,
        collection,
        page
    },
    data(){
        return{
            banner:{},//banner

            cateList:[],//分类
            cateIndex:0,

            font_search_total:0,//作品总数
            listOne:[],//搜索字体
            listTwo:[],//搜索每周上榜

            isShow:false,//收藏夹显示
            
            keywords:'',//关键词
            // 分页
            total:0,//总数
            page:1,//页数
            limit:16,//每页个数

            // 收藏参数
            type:'',//类型
            collId:'',//收藏id
        }
    },
    mounted(){
        this.$parent.routerIndex = 5;
        this.$parent.showIndex = 1;
        // new WOW().init();
        this.bannerOneban();
        if(this.$route.query.keywords){
            this.keywords = this.$route.query.keywords
        }
        this.cateList = JSON.parse(JSON.stringify(this.$store.state.navList.font_search_cate));
        this.font_search_total = this.$store.state.navList.font_search_total;
        this.page = 1;
        this.listFun();

        this.$refs.downtotal.listFun()
    },
    methods:{
        // 获取内部banner、 
		bannerOneban(){
			var that = this;
            this.$api.POST(this.$face.bannerOneban,{location:6},function(res){
                switch(res.data.links) {
                    case 0:
                        res.data.url = '';
                        break;
                    case 1:
                        res.data.url = 'material';
                        break;
                    case 2:
                        res.data.url = 'video';
                        break;
                    case 3:
                        res.data.url = 'deformation';
                        break;
                    case 4:
                        res.data.url = 'ranking';
                        break;
                    case 5:
                        res.data.url = 'dailyWord';
                        break;
                    case 6:
                        res.data.url = 'fontSearch';
                        break;
                    case 7:
                        res.data.url = 'dailyWordUpload';
                        break;
                    case 8:
                        res.data.url = 'vip';
                        break;
                    case 9:
                        res.data.url = 'project';
                        break;
                    case 10:
                        res.data.url = 'rankingDetail';
                        break;
                    case 11:
                        res.data.url = 'projectDetail';
                        break;
                }
                that.banner = res.data
            })
		},

        listFun(){
            this.listOne = [];
            this.listTwo = [];
            

            if(this.keywords){
                this.cateIndex = 0
            }
            var that = this;
            var params ={
                page: this.page,
                limit: this.limit,
                category: this.cateList[this.cateIndex].id,
                _keywords: this.keywords
            }
            this.$api.POST(this.$face.onlistSearchs,params,function(res){
                if(that.keywords){
                    that.listTwo = res.data.list;
                }else{
                    that.listOne = res.data.list;
                }
                that.total = res.data.total;
            })
        },
        
        // 查看详情
        toDetail(id){
            localStorage.setItem('clearAll',2)
            //this.$router.push({name:'fontSearchDetail',query:{id:id}})
            let routeUrl = this.$router.resolve({
				name: "fontSearchDetail",
				query: {
					id: id
				}
			});
			window.open(routeUrl.href, '_blank');
        },
        // 分类筛选
        cateSelect(e){
            this.page = 1;
            this.cateIndex = e;
            this.keywords = '';
            if(this.$refs.page){
                this.$refs.page.currentPage = 1;
            }
            this.listFun()
        },

        //收藏列表
        collectFun(type,id,index){
            this.type = type;
            this.collId = id;
            this.isShow = true;
        },
        //取消收藏
        uncollectFun(type,id,index){
            var that = this;
            var params ={
                coll_id: id,
                type: type,
            }
            this.$api.POST(this.$face.collectionColl,params,function(res){
                that.list[index].is_collect = 0;
                that.$utile.prompt('success','取消收藏');
            })
        },

        //关闭收藏
        closeFun(){
            this.isShow = false
        },
        // 点击遮罩层关闭
        closeMsg(ev){
            let dom = document.getElementById("child");
            if(dom){
                if(!dom.contains(ev.target)){
                    this.isShow = false
                }
            } 
        },
    },
    watch:{
        '$store.state.navList'(newval){
            this.cateList = JSON.parse(JSON.stringify(newval.font_search_cate));
            this.font_search_total = newval.font_search_total;
            this.page = 1;
            this.listFun()
        }
    },
    //使用keepAlive缓存组件状态，页面切换路由会触发 activated 钩子函数。在 activated 中将 之前记录的位置赋值给引起滚动条滚动元素的 scrollTop
    activated(){
        this.$nextTick(() => {
            
            this.$parent.routerIndex = 5;
            this.$parent.showIndex = 1;
            if(localStorage.getItem('clearAll') != 2){
                this.page = 1;
                if(this.$refs.page){
                    this.$refs.page.currentPage = 1;
                }
                this.cateIndex = 0;
                this.keywords = '';
                this.listFun()
            }
            localStorage.removeItem('clearAll')
            // setTimeout(() => {
            //     document.documentElement.scrollTop = this.scroll;
            // }, 0);
        });
    }
};
</script>

<style scoped>
.project {
    background-color: #f6f6f6;
}

.material_screen {
    width: 100%;
    background: #ffffff;
    padding-bottom: 20px;
}

.inner_nav {
    padding: 27px 0 17px;
}
.inner_nav_l {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 12px;
    letter-spacing: 0px;
    color: #999999;
}
.inner_nav_r {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 12px;
    letter-spacing: 0px;
    color: #999999;
}
.inner_nav_r span {
    color: #5957FF;
}

.inner_banner {
    display: block;
    margin-bottom: 24px;
}
.inner_banner img {
    width: 100%;
}

.material_search {
    width: 100%;
    height: 70px;
    background-color: #f6f7f9;
    border-radius: 10px;
    border: solid 1px #eeeeee;
    box-sizing: border-box;
    padding-right: 160px;
    position: relative;
    margin-bottom: 29px;
}
.material_search input {
    background: transparent;
    width: 100%;
    height: 100%;
    padding: 0 24px;
    box-sizing: border-box;
    font-size: 16px;
}
.material_search input::placeholder {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #999999;
}

.material_search_button{
    position: absolute;
    top: 2px;
    right: 2px;
    width: 150px;
    height: 64px;
    background-image: linear-gradient(0deg, 
    #5957FF 0%, 
    #a5a3ffcb 100%);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}



.project_box{
    padding-bottom: 54px;
}
/* 专题 */
.daily{
    margin: 34px -13.33px 45px;
}
.daily_li{
    margin:0 13.33px 30px;
}
.daily_li_box{
    width: 330px;
    height: 218px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}
.daily_li_box img{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    transition: 0.3s;
}
.daily_li_mask{
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    border-radius: 10px;
    background: rgba(0,0,0,.4);
    opacity: 0;
    z-index: 33;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    cursor: default;
}
.daily_li:hover .daily_li_mask{
    opacity: 1;
}
.daily_li:hover .daily_li_box img{
    transform: scale(1.08)
}


/* @media screen and (max-width: 1600px) {
        .project_li,.project_li_box,.video_li_box,.ranking_li_box{
            width: 280px;
            height: 184.4px;
        }
        .project_li{
            background-size: 261px auto;
        }
        .entrance_nav_li{
            width: 280px;
        }
        .provide_li{
            width: 270px;
        }
        .member_box{
            width: 580px;
        }
    }
    @media screen and (max-width: 1400px) {
        .project_li,.project_li_box,.video_li_box,.ranking_li_box{
            width: 240px;
            height: 158px;
        }
        .project_li{
            background-size: 221px auto;
        }
        .entrance_nav_li{
            width: 240px;
        }
        .provide_li{
            width: 230px;
        }
        .member_box{
            width: 500px;
        }
    } */

.material_class_item{
    position: relative;
}
.class_list_title{
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    line-height: 30px;
}
.class_list{
    min-height: 30px;
    width: 100%;
    padding-left: 44px;
    box-sizing: border-box;
}
.class_list>div{
    height: 30px;
    line-height: 28px;
    font-size: 15px;
    text-align: center;
    color: #333333;
    border: solid 1px transparent;
    box-sizing: border-box;
    margin:0 7px;
    margin-bottom: 10px;
    padding: 0 10px;
    border-radius: 10px;
    cursor: pointer;
}
.class_list>div.active{
   color: #221eff;
    background: #ebebff;
}

.sidebar_r{
    right: 2%;
    left: auto;
}

.ranking_ul{
    margin: 28px -13.33px 26px;
}
.ranking_daily_li{
    width: 330px;
    height: 263px;
    border-radius: 10px;
    margin:0 13.33px 30px;
    padding: 14px 15px 0;
    box-sizing: border-box;
    background-color: #FFF;
}
.ranking_li_box{
    width: 100%;
    height: 198px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}
.ranking_li_box img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
}
.ranking_li_name{
    font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 51px;
	letter-spacing: 0px;
	color: #333333;
}

.ranking_daily_li:hover .ranking_li_box img{
    transform: scale(1.08)
}
</style>